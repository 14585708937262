<template>
  <div>
    <div
      id="editor"
      class="modal fade"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <!-- eslint-disable vue/no-v-html -->
            <h5
              class="h5 mt-2"
              v-html="term.title"
            />
            <!-- eslint-enable vue/no-v-html -->

            <div class="edit-button ms-3">
              <button
                v-if="isViewing"
                type="button"
                class="btn btn-primary"
                @click="edit"
              >
                Edit
              </button>
            </div>

            <button
              type="button"
              class="btn btn-close"
              aria-label="Close"
              @click="closeModal"
            >
              <span aria-hidden="true" />
            </button>
          </div>

          <div class="modal-body">
            <wts-alert
              status="success"
              :message="success"
              @alert-displayed="reset()"
            />
            <div
              v-if="isViewing"
              :class="mode"
            >
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="term.content" />

              <div>
                Categories: {{ categoryNames }}
              </div>

              <div>Tags: {{ tagNames }}</div>
              <div>Is Free: {{ isFree }}</div>
            </div>

            <div
              v-else-if="isEditing"
              id="mce-editor"
              :class="mode"
              class="editor"
            >
              <form>
                <div class="mb-3 row g-2">
                  <label
                    :for="`entry-${id}`"
                    class="col-auto col-form-label"
                  >Title Edit</label>
                  <div class="col-auto">
                    <input
                      :id="`entry-${id}`"
                      v-model="titleText"
                      type="text"
                      class="col-auto form-control"
                    >
                  </div>
                </div>

                <div class="categories-container my-3">
                  <wts-alert
                    status="danger"
                    :message="newCategory.error"
                  />
                  <div
                    v-for="category in term.categories"
                    :key="category.id"
                    class="badge text-bg-light text-dark mx-1"
                  >
                    {{ category.name }}
                    <button
                      type="button"
                      class="btn-close"
                      aria-label="Delete"
                      @click="removeCategory(category)"
                    />
                  </div>
                  <div class="badge text-bg-light dropdown mx-1 py-2">
                    <a
                      class="dropdown-toggle text-decoration-none"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >Categories 🏷️</a>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li>
                        <div class="dropdown-item">
                          <input
                            id="new-category"
                            v-model="newCategory.name"
                            type="text"
                            class="form-control"
                            placeholder="Add Category"
                            @keyup.enter="addCategory()"
                          >
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="tags-container my-3">
                  <wts-alert
                    status="danger"
                    :message="newTag.error"
                  />
                  <div
                    v-for="tag in term.tags"
                    :key="tag.id"
                    class="badge text-bg-light mx-1"
                  >
                    {{ tag.name }}
                    <button
                      type="button"
                      class="btn-close"
                      aria-label="Delete"
                      @click="removeTag(tag)"
                    />
                  </div>

                  <div class="badge text-bg-light dropdown mx-1 py-2">
                    <a
                      class="dropdown-toggle text-decoration-none"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >Tags
                      🏷️</a>
                    <ul class="dropdown-menu dropdown-menu-end">
                      <li>
                        <div class="dropdown-item">
                          <input
                            id="new-tag"
                            v-model="newTag.name"
                            type="text"
                            class="form-control"
                            placeholder="Add Tag"
                            @keyup.enter="addTag()"
                          >
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="mb-3">
                  <div
                    class="form-check d-flex align-items-center"
                    style="padding-left: 0.5em;"
                  >
                    <label
                      class="form-check-label me-2"
                      for="is-free"
                    >Is Free:</label>
                    <input
                      id="is-free"
                      v-model="isFree"
                      class="form-check-input m-0"
                      type="checkbox"
                    >
                  </div>
                </div>
                <div class="mb-3">
                  <tinymce
                    v-model="term.content"
                    :api-key="`${tinyCloudKey}`"
                    :init="{
                      plugins: 'autoresize lists table code help save',
                      toolbar: 'undo redo | styles | bold italic | code',
                      menubar: false,
                      theme_advanced_buttons3_add: 'save',
                      save_enablewhendirty: false,
                      save_oncancelcallback: cancel,
                      save_onsavecallback: save
                    }"
                    @init="setupEditor"
                  />
                </div>
              </form>
            </div>
            <div
              v-else-if="isSaving"
              :class="mode"
            >
              Saving...
            </div>
            <div
              v-else-if="isLoading"
              :class="mode"
            >
              Loading...
            </div>
            <div
              v-else-if="isDeleting"
              :class="mode"
            >
              Deleting...
            </div>
            <div
              v-else
              :class="mode"
            >
              Unknown mode
            </div>
          </div>

          <div class="modal-footer d-flex justify-content-between">
            <wts-alert
              status="danger"
              :message="error"
              @alert-displayed="reset()"
            />
            <div class="left-side">
              <button
                v-if="isEditing && id"
                type="button"
                class="btn btn-danger"
                aria-label="close"
                @click="showDeleteTermModal"
              >
                Delete
              </button>
            </div>
            <div class="right-side">
              <button
                v-if="isEditing"
                type="button"
                class="btn btn-primary me-2"
                :disabled="!isDirty || !validateTitle()"
                @click="save"
              >
                Save
              </button>
              <button
                v-if="isEditing && id"
                type="button"
                class="btn btn-secondary"
                aria-label="close"
                @click="cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete term confirmation modal-->
    <div
      id="deleteTermModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Delete term confirmation
            </h5>
            <button
              type="button"
              class="btn btn-close"
              aria-label="Close"
              @click="closeDeleteTermModal()"
            />
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete the term {{ term.title }}? </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              @click="deleteTerm()"
            >
              Delete
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              @click="closeDeleteTermModal()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '../Alert';
import DictionaryService from '../../../services/admin/dictionary.service';
import removeElement from '../../../helpers/remove-element';
import TinyMce from '@tinymce/tinymce-vue';
import { Modal } from 'bootstrap';
import { trackError } from '@/helpers/google-analytics-tracker';

export default {
  name: 'Editor',
  components: {
    tinymce: TinyMce,
    wtsAlert: Alert
  },
  props: {
    id: {
      type: Number,
      default: null
    },
  },
  emits: ['wts:term-created', 'wts:term-deleted'],
  data() {
    const d = Object.assign({
      mode: 'edit',
      modal: null,
      isDirty: false,
      editor: null,
      tinyCloudKey: process.env.VUE_APP_TINY_CLOUD_KEY,
      timeout: 3000,
      confirmDeleteModal: null,
    },
      this.emptyData()
    );
    return d;
  },
  computed: {
    isViewing() { return this.mode === 'show' },
    isEditing() { return this.mode === 'edit' },
    isLoading() { return this.mode === 'loading' },
    isSaving() { return this.mode === 'saving' },
    isDeleting() { return this.mode === 'deleting' },
    content() {
      return this.term.content;
    },
    title() {
      return this.term.title;
    },
    categories() {
      return this.term.categories;
    },
    categoryNames() {
      return this.term.categories.map((cat) => cat.name).join(", ");
    },
    tagNames() {
      return this.term.tags.map((tag) => tag.name).join(", ");
    },
    titleText: {
      get() {
        return this.toText(this.term.title);
      },
      set(newVal) {
        this.term.title = newVal;
      }
    },
    isFree: {
      get() {
        return this.term.is_free
      },
      set(newVal){
        this.term.is_free = newVal;
      }
    },
  },
  watch: {
    async id(newVal) {
      return this.fetchContent(newVal);
    },
    title(newVal) {
      if (this.isEditing && (this.id || newVal)) {
        this.isDirty = true;
      }
    },
    content(newVal) {
      if (this.isEditing && (this.id || newVal)) {
        this.isDirty = true;
      }
    },
    error(newVal) {
      if (newVal) {
        this.reset();
      }
    },
    isFree(newVal) {
      if (this.isEditing && (this.id || newVal)) {
        this.isDirty = true;
      }
    },
  },
  mounted() {
    const el = document.getElementById('editor')
    this.modal = new Modal(el, {
      keyboard: false,
      backdrop: 'static',
    });
    el.addEventListener('hidePrevented.bs.modal', () => {
      if (!this.isDirty) {
        this.modal.hide()
      }
    })
    document.addEventListener('focusin', (e) => {
      if (e.target.closest(".tox-dialog")) {
        console.log("Stopping Immediate Propagation");
        e.stopImmediatePropagation();
      }
    })
    this.confirmDeleteModal = new Modal(document.getElementById('deleteTermModal'));

  },
  methods: {
    setupEditor(_event, editor) {
      this.editor = editor;
    },
    closeModal() {
      if (!this.isDirty || confirm("You have unsaved changes. Are you sure you want to close?")) {
        this.modal.hide();
      }
    },
    show() {
      this.modal.show();
    },
    edit() {
      this.mode = "edit";
    },
    async save() {
      this.mode = "saving";
      try {
        const resp = await DictionaryService.save(this.term);
        if (resp.status === 200) {
          console.log("saved!");
          this.success = "Successfully saved!";
          this.mode = "show";
          const { data } = resp;
          this.term = data.term;
        }
        else if (resp.status === 201) {
          this.success = "Successfully saved!";
          this.mode = "show";
          const { data } = resp;
          this.term = data.term;
          this.$emit('wts:term-created', data.term)
        }
        this.isDirty = false;
      }
      catch (e) {
        this.mode = "edit"

        this.error = e.message;
        console.log("Error: ", this.error)
        trackError(e);
      }
    },
    async cancel() {
      this.newCategory = {};
      this.newTag = {};
      this.error = "";
      this.success = "";
      try {
        await this.fetchContent(this.id);
      }
      catch (e) {
        trackError(e)
      }
    },
    async deleteTerm() {
      this.mode = "deleting";
      try {
        const resp = await DictionaryService.deleteTerm(this.term.id);
        if (resp.status === 204) {
          this.$store.commit('setSuccessAlertMsg', "You successfully deleted the " + this.term.title + " term.");
          this.$emit('wts:term-deleted', this.term);
          this.confirmDeleteModal.hide();
          this.modal.hide();
          this.emptyData();
        }
        this.isDirty = false;
      }
      catch (e) {
        this.error = e.message;
        console.log("Error: ", this.error);
        this.mode = "edit";
        this.confirmDeleteModal.hide();
        trackError(e);
      }
    },
    async fetchContent(id) {
      this.mode = "loading";

      if (!id) {
        console.log(this.term);
        Object.assign(this, this.emptyData());
        console.log(this.term);
        this.isDirty = false;
        this.mode = "edit";
        return;
      }

      try {
        const resp = await DictionaryService.find(id);
        const { term } = resp.data;
        if (term) {
          this.term = term;
          this.isDirty = false;
          this.mode = "show";
        }
        else {
          const error = "No term in response data";
          this.error = error;
          console.log(error);
          trackError(error);
        }
      }
      catch (e) {
        this.error = e.message;
        console.log('Error:', e.message)
      }
    },
    reset(obj) {
      if (!obj) { obj = this; }

      const resolve = () => setTimeout(() => {
        obj.success = obj.error = "";
      }, this.timeout);
      return resolve();
    },
    async addCategory() {
      this.newCategory.error = "";

      const { name } = this.newCategory;
      if (!name) { return false; }

      if (this.term.categories.filter((cat) => cat.name === name).length) {
        console.log(`Category "${name}" already exists on term. Not saving.`)
        this.newCategory.error = `Category "${name}" already exists on term. Not saving.`
        return false;
      }

      if (!this.term.id) {
        this.term.categories.push({ name: name });
        this.newCategory = {};
        return false;
      }

      try {
        const response = await DictionaryService.addCategory(this.id, name);
        const category = response.data
        this.term.categories.push(category);
        this.newCategory = {};
        return category;

      }
      catch (e) {
        console.log("Error: ", e.message)
        trackError(e)
      }
    },
    async removeCategory(category) {
      const i = this.term.categories.indexOf(category)
      if (!this.id) {
        this.term.categories = removeElement(this.term.categories, i)
      }
      try {
        const response = await DictionaryService.removeCategory(this.id, category.id);
        if (response.status === 200) {
          this.term.categories = removeElement(this.term.categories, i)
          console.log(`Removed ${category.name}`)
        }
      }
      catch (e) {
        this.error = e.message;
        console.log("Error: ", e.message)
        trackError(e)
      }
    },
    async addTag() {
      this.newTag.error = "";
      const { name } = this.newTag;
      if (!name) {
        console.log("Attempt to automatically save tag, but field was empty.")
        return false;
      }

      if (this.term.tags.filter((tag) => tag.name === name).length) {
        console.log(`Tag "${name}" already exists on term, not saving`)
        this.newTag.error = `Tag "${name}" already exists on term, not saving`
        return false;
      }

      if (!this.term.id) {
        this.term.tags.push({ name: name });
        this.newTag = {};
        return false;
      }

      try {
        const response = await DictionaryService.addTag(this.id, name);
        const tag = response.data;
        this.term.tags.push(tag);
        this.newTag = {};
        return tag;
      }
      catch (e) {
        console.log("Error: ", e.message)
        trackError(e)
      }
    },
    async removeTag(tag) {
      const i = this.term.tags.indexOf(tag);

      if (!this.id) {
        this.term.tags = removeElement(this.term.tags, i);
        return;
      }
      try {
        const response = await DictionaryService.removeTag(this.id, tag.id);
        if (response.status === 200) {
          this.term.tags = removeElement(this.term.tags, i);
          console.log(`Removed ${tag.name}`);
        }
      }
      catch (e) {
        console.log("Error: ", e.message)
        trackError(e)
      }
    },
    toText(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent;
    },
    emptyData() {
      return {
        term: {
          id: null,
          content: null,
          title: null,
          categories: [],
          tags: [],
        },
        newCategory: {
          name: null,
          error: "",
        },
        newTag: {
          name: null,
          error: "",
        },
        error: "",
        success: "",
      };
    },
    showDeleteTermModal() {
      this.confirmDeleteModal.show();
    },
    closeDeleteTermModal() {
      this.confirmDeleteModal.hide();
    },
    validateTitle(){
      return this.titleText.trim().length > 0;
    }
  },
}
</script>
